import "./userForm.scss";

import { h, Component } from "preact";

import { SrvM, CnstM, ActM, ModM } from "../modules";
import { Checkbox } from "../components";
import { Routes } from "../routes";
import { ShareType } from "../constants";
import { AgeConfirmationPopup } from "../components/popup";

const ShareTypeRegExMap = {
  [CnstM.ShareType.Email]: CnstM.Constants.Validation.EmailRegEx,
  [CnstM.ShareType.Phone]: CnstM.Constants.Validation.PhoneRegEx,
};

interface IUserFormState {
  session: ModM.ISession;
  canSubmit: boolean;
  showPopup: boolean;
  ageConfirmed: boolean; // New state for age confirmation
}

export class UserFormPage extends Component<
  any,
  IUserFormState & { showPopup: boolean }
> {
  state = {
    session: new ModM.Session(),
    canSubmit: false,
    showPopup: true, // Check if age was already confirmed
    ageConfirmed:
      sessionStorage.getItem(CnstM.StorageKey.AgeConfirmed) === "true", // Set based on previous decision
  };

  public componentWillMount() {
    const contactInfo =
      sessionStorage.getItem(CnstM.StorageKey.ContactInfo) || "";
    const tosAgreed =
      sessionStorage.getItem(CnstM.StorageKey.TOSAgreed) === "1";
    const tosPrivacyAgreed =
      sessionStorage.getItem(CnstM.StorageKey.TOSPrivacyAgreed) === "1";
    const optIn =
      sessionStorage.getItem(CnstM.StorageKey.OptInAccepted) === "1";
    const shareType =
      (sessionStorage.getItem(CnstM.StorageKey.ShareType) as CnstM.ShareType) ||
      CnstM.ShareType.Email;
    const ageConfirmed =
      sessionStorage.getItem(CnstM.StorageKey.AgeConfirmed) === "true";
    this.setState({
      session: {
        ...this.state.session,
        contactInfo,
        tosAgreed,
        tosPrivacyAgreed,
        optIn,
        shareType,
      },
      // showPopup: !ageConfirmed, // Only show the popup if age is not confirmed
      // ageConfirmed, // Update the state with the value from sessionStorage
    });
    console.log(
      "ComponenetWillMount  " +
        sessionStorage.getItem(CnstM.StorageKey.TOSPrivacyAgreed) +
        " " +
        sessionStorage.getItem(CnstM.StorageKey.OptInAccepted) +
        " " +
        sessionStorage.getItem(CnstM.StorageKey.TOSAgreed)
    );
  }

  public componentDidMount() {
    //console.log("componentDidMount:", this.state.ageConfirmed);
    this.validate();
    // const ageConfirmed =
    // sessionStorage.getItem(CnstM.StorageKey.AgeConfirmed) === "true";
    // console.log("ComponenetDidMount AGE Confirmed "+ageConfirmed);
  }

  public handlePopupClose = (isOfAge: boolean) => {
    //console.log(`Popup closed with age confirmed: ${isOfAge}`);
    // Store the age confirmation status in sessionStorage
    sessionStorage.setItem(
      CnstM.StorageKey.AgeConfirmed,
      isOfAge ? "true" : "false"
    );

    // If age is confirmed, set default checkbox states
    if (isOfAge) {
      this.setState({
        showPopup: false,
        ageConfirmed: true,
        session: {
          ...this.state.session,
          tosPrivacyAgreed: false, //tosPrivacyAgreed: false, // Preselect tosPrivacyAgreed
          optIn: false, // Ensure optIn is not preselected
          tosAgreed: true,
        },
      });
    } else {
      this.setState({
        showPopup: false,
        ageConfirmed: false,
        session: {
          ...this.state.session,
          tosAgreed: false,
          // tosPrivacyAgreed: true, // Preselect tosPrivacyAgreed
          // optIn: false, // Ensure optIn is not preselected
        },
      });
    }

    const ageConfirmed =
      sessionStorage.getItem(CnstM.StorageKey.AgeConfirmed) === "true";
    //console.log("handlePopupClose AGE Confirmed "+ageConfirmed);
  };

  public render() {
    const { showPopup, ageConfirmed } = this.state;

    const { contactInfo, tosAgreed, tosPrivacyAgreed, optIn, shareType } =
      this.state.session;
    const isEmailSharing = shareType === CnstM.ShareType.Email;
    const pageClasses = `page-form align-cc ${
      isEmailSharing ? "page-form-email" : "page-form-phone"
    }`;
    const pageTitle = SrvM.i18n.txt(
      isEmailSharing ? CnstM.StringKey.EnterEmail : CnstM.StringKey.EnterMobile
    );

    const language = SrvM.i18n.getLang();
    const tosPrivacyText = SrvM.i18n
      .txt(CnstM.StringKey.TOS_Privacy)
      .replace(
        `{link_${language}}`,
        language === "en"
          ? "https://about.att.com/privacy.html"
          : "https://www.att.com/es-us/sdabout/privacy.html"
      );

    console.log("Render AGE Confirmed " + ageConfirmed);
    console.log("Privacy agreed " + tosPrivacyAgreed);
    return (
      <section class={pageClasses}>
        {showPopup && <AgeConfirmationPopup onClose={this.handlePopupClose} />}
        <h1 class="page-title">{pageTitle}</h1>
        <form class="align-cc" onSubmit={this.handleSubmit}>
          <input
            class="form-input"
            name="contactInfo"
            type={isEmailSharing ? "email" : "tel"}
            autoComplete={isEmailSharing ? "email" : "tel-national"}
            x-autocomplete={isEmailSharing ? "email" : "phone-national"}
            onKeyUp={this.handleFieldChange}
            value={contactInfo}
          />
          <span class="check-header text-glow">
            {SrvM.i18n.txt(CnstM.StringKey.ByCheckingTheFollowing)}
          </span>
          <div class="check-group">
            {/* Conditionally render the tosPrivacyAgreed checkbox */}

            {/* <Checkbox
              name="tosPrivacyAgreed"
              required // Required if age is confirmed
              checked={true} // Always checked if age is confirmed
              disabled={!ageConfirmed} // Enabled if age is confirmed
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: tosPrivacyText,
                  }}
                />
              }
              onChange={this.handleFieldChange}
            /> */}

            {ageConfirmed ? (
              <Checkbox
                name="tosPrivacyAgreed"
                required={true} // Required if age is confirmed
                checked={this.state.session.tosPrivacyAgreed} //{this.state.session.tosPrivacyAgreed}// Always checked if age is confirmed
                disabled={false} // Enabled if age is confirmed
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: tosPrivacyText,
                    }}
                  />
                }
                onChange={this.handleFieldChange}
              />
            ) : (
              <Checkbox
                name="tosPrivacyAgreed"
                required={true} // Not required if age is not confirmed
                checked={false} // Use session state if age is not confirmed
                disabled={true} // Disabled if age is not confirmed
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: tosPrivacyText,
                    }}
                  />
                }
                onChange={this.handleFieldChange}
              />
            )}

            <Checkbox
              name="optIn"
              checked={this.state.session.optIn}
              disabled={!ageConfirmed} // Disable if age is not confirmed
              required={false} // Not required regardless of age confirmation
              label={SrvM.i18n.txt(CnstM.StringKey.OptIn)}
              onChange={this.handleFieldChange}
            />
          </div>
          <button class="button3">
            {SrvM.i18n.txt(CnstM.StringKey.Submit)}
          </button>
        </form>
      </section>
    );
  }

  // handlers

  private handleFieldChange = (e: any) => {
    console.log(
      "BEFORE Handlefield change AGECONFIRMED " + this.state.ageConfirmed
    );
    const isCheckbox = e.target.type === "checkbox";
    const isPhoneNumber =
      !isCheckbox && this.state.session.shareType === ShareType.Phone;
    const value = isCheckbox
      ? e.target.checked
      : isPhoneNumber
      ? this.sanitizePhone(e.target.value)
      : e.target.value;
    this.setState(
      {
        session: {
          ...this.state.session,
          [e.target.name]: value,
        },
      },
      () => this.validate()
    );
    console.log(
      "AFTER Handlefield change AGECONFIRMED " + this.state.ageConfirmed
    );
  };

  private handleSubmit = (e: any) => {
    if (e) e.preventDefault();

    if (!this.state.canSubmit) {
      ActM.SessionActions.saveSessionToStorage(this.state.session);
      ActM.AppActions.route(Routes.Submit);
      return;
    }

    ActM.SessionActions.register(this.state.session);
  };

  // private handleSubmit = (e: any) => {
  //   if (e) e.preventDefault();

  //   if (!this.state.canSubmit) {
  //     ActM.SessionActions.saveSessionToStorage(this.state.session);
  //     ActM.AppActions.route(Routes.Submit);
  //     return;
  //   }

  //   ActM.SessionActions.register(this.state.session);
  // };

  // helpers

  private validate(): boolean {
    const { contactInfo, shareType, tosAgreed, tosPrivacyAgreed } =
      this.state.session;

    const { ageConfirmed } = this.state;

    let value = contactInfo || "";
    if (shareType === CnstM.ShareType.Phone) {
      value = this.sanitizePhone(value);
    }

    let canSubmit: boolean = true;
    const match = ShareTypeRegExMap[shareType].exec(value);
    if (!match || match[0].length !== value.length) {
      canSubmit = false;
      if (shareType === CnstM.ShareType.Phone) {
        this.saveValidationError(SrvM.i18n.txt(CnstM.StringKey.PhonePopup));
      } else {
        this.saveValidationError(SrvM.i18n.txt(CnstM.StringKey.EmailPopup));
      }

      this.setState({ canSubmit });
      return canSubmit;
    }

    // If age is not confirmed, skip validation for the checkboxes
    if (!ageConfirmed) {
      this.setState({ canSubmit });
      return canSubmit;
    }

    //if (!tosAgreed || !tosPrivacyAgreed) {
    // Validate checkboxes only if age is confirmed
    if (!tosPrivacyAgreed) {
      canSubmit = false;
      this.saveValidationError(SrvM.i18n.txt(CnstM.StringKey.CheckboxPopup));
      this.setState({ canSubmit });
      return canSubmit;
    }

    this.setState({ canSubmit });
    return canSubmit;
  }

  private saveValidationError(text: string) {
    this.setState({
      session: { ...this.state.session, submit: text },
    });
  }

  private sanitizePhone(input: string) {
    return input.replace(/\D/g, "");
  }
}
